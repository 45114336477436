import {behaviours} from '../behaviours/behaviour'

behaviours.addBehaviour(
    (() => {
        const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1)

        const attach = function () {
            document.querySelectorAll('div[data-background]').forEach((block) => {
                const background = block.getAttribute('data-background')
                const previousBlock = block.previousElementSibling
                const nextBlock = block.nextElementSibling

                if (block.getAttribute('data-pull-background') === 'bottom' || block.getAttribute('data-pull-background') === 'both') {
                    if (nextBlock && nextBlock.hasAttribute('data-background')) {
                        block.classList.add(`${block.classList[0]}--backgroundBottom${capitalizeFirstLetter(nextBlock.getAttribute('data-background'))}`)
                    }
                }

                if (nextBlock && nextBlock.hasAttribute('data-background')) {
                    block.setAttribute('data-next-background', nextBlock.getAttribute('data-background'))
                }

                if (block.getAttribute('data-pull-background') === 'top' || block.getAttribute('data-pull-background') === 'both') {
                    if (previousBlock && previousBlock.hasAttribute('data-background')) {
                        block.classList.add(`${block.classList[0]}--backgroundTop${capitalizeFirstLetter(previousBlock.getAttribute('data-background'))}`)
                    }
                }

                if (!previousBlock || !previousBlock.hasAttribute('data-background')) {
                    return
                }

                previousBlock.setAttribute('data-next-block', block.classList[0])

                if (previousBlock.getAttribute('data-background') === background && previousBlock.classList.contains(block.classList[0])) {
                    previousBlock.classList.add(`${previousBlock.classList[0]}--nextBlockSameBackgroundSameType`)
                    previousBlock.setAttribute('data-next-block', block.classList[0])
                } else if (previousBlock.getAttribute('data-background') === background) {
                    previousBlock.classList.add(`${previousBlock.classList[0]}--nextBlockSameBackground`)
                    previousBlock.setAttribute('data-next-block', block.classList[0])
                }
            })
        }

        return {
            attach: attach,
        }
    })()
)
