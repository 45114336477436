import {behaviours} from '../../../global/scripts/behaviours/behaviour.ts'
;(function ($) {
    const FiltersBehaviour = (function () {
        const attach = function (context) {
            const blocks = context.querySelectorAll('.filters')

            if (blocks.length === 0) {
                return
            }

            blocks.forEach((block) => init(block))
        }

        const init = function (block) {
            block.querySelectorAll('select').forEach((element) => {
                if (!element.parentElement.id) {
                    element.parentElement.id = (Math.random() * 100000).toFixed().toString()
                }

                $(element).select2({
                    minimumResultsForSearch: 100,
                    dropdownParent: element.parentElement,
                    debug: true,
                })
            })
        }

        return {
            attach: attach,
        }
    })()
    behaviours.addBehaviour(FiltersBehaviour)
})(jQuery)
