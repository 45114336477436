import {behaviours} from '../behaviours/behaviour.ts'
;(function ($) {
    /**
     * Scroll behaviour
     */
    const ScrolledBehaviour = {
        /**
         * Attach behaviour
         *
         * @param context
         */
        attach: function (context) {
            if ($(context).find('body').length && !$(context).find('.docs').length) {
                this.initOnScroll()
            }
        },

        /**
         * Add/Remove scrolled class on scroll
         */
        initOnScroll: function () {
            $(window).scroll(function () {
                var scrollTop = $(this).scrollTop()
                document.documentElement.classList.toggle('scrolled', scrollTop > 10)
                document.documentElement.classList.toggle('scrolled--far', scrollTop > 50)
            })
            $(window).trigger('scroll')
        },
    }

    behaviours.addBehaviour(ScrolledBehaviour)
})(jQuery)
