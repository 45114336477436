import 'plyr/src/sass/plyr.scss'
import {behaviours} from '../../../global/scripts/behaviours/behaviour'
import Plyr from 'plyr'

type initVideoInterface = (
    element: HTMLElement,
    module: {
        default: typeof Plyr
    }
) => void

const initVideo: initVideoInterface = function (element: HTMLElement) {
    import(/* webpackChunkName: "video" */ 'plyr').then((module) => {
        const Plyr = module.default
        const hero = element.closest('.hero')
        const heroLegacy = element.closest('.heroLegacy')

        const autoplay = !!element.hasAttribute('data-autoplay')
        const background = !!element.hasAttribute('data-background')
        let playerConfig: Plyr.Options = {
            clickToPlay: undefined,
        }

        if (background) {
            playerConfig = {
                ...playerConfig,
                controls: [],
                fullscreen: {
                    enabled: false,
                    fallback: false,
                },
                vimeo: {
                    background: true,
                },
                loop: {
                    active: true,
                },
            }
        }

        if (autoplay) {
            playerConfig.clickToPlay = false
        }

        const player = new Plyr(element, playerConfig)

        if (autoplay) {
            player.on('ready', () => {
                player.muted = true
                player.play()
            })
        }

        if (hero) {
            player.on('playing', () => {
                hero.classList.add('hero--videoStarted')
            })
        }

        if (heroLegacy) {
            player.on('playing', () => {
                heroLegacy.classList.add('heroLegacy--videoStarted')
            })
        }
    })
}

const videoBehaviour = (function () {
    const attach = function (context: HTMLElement) {
        const videos = context.querySelectorAll<HTMLElement>('.video')

        if (videos.length === 0) {
            return false
        }

        import(/* webpackChunkName: "video" */ 'plyr').then((module) => {
            videos.forEach((video) => initVideo(video, module))
        })
    }
    return {
        attach: attach,
    }
})()
behaviours.addBehaviour(videoBehaviour)
