import {Fancybox} from '@fancyapps/ui'
import '@fancyapps/ui/dist/fancybox/fancybox.css'
import {behaviours} from '../../../global/scripts/behaviours/behaviour.ts'

const blockImagesBehaviour = (function () {
    const attach = function (context) {
        const blocks = context.querySelectorAll('.blockImages')

        if (blocks.length === 0) {
            return
        }

        Fancybox.bind('[data-fancybox]', {
            infinite: false,
            preload: 0,
        })
    }

    return {
        attach: attach,
    }
})()
behaviours.addBehaviour(blockImagesBehaviour)
