import {behaviours} from '../../../global/scripts/behaviours/behaviour.ts'

const blockImageSliderBehaviour = (function () {
    const attach = function (context) {
        const blocks = context.querySelectorAll('.blockImageSlider')

        if (blocks.length === 0) {
            return
        }

        // Async load swiper library and init slideshow when loaded
        import(/* webpackChunkName: "slider" */ 'swiper').then((module) => {
            blocks.forEach((block) => init(block, context, module))
        })
    }

    const init = function (block, context, module) {
        const Swiper = module.default
        const baseClass = 'blockImageSlider'
        const navClass = `${baseClass}__navigationItem`
        const slider = block.querySelector(`.${baseClass}__slider`)
        const wrapperClass = `${baseClass}__items`
        const pagination = block.querySelector(`.${baseClass}__pagination`)
        const slideClass = `${baseClass}__item`
        const buttonNext = block.querySelector(`.${navClass}--next`)
        const buttonPrev = block.querySelector(`.${navClass}--previous`)

        new Swiper(slider, {
            modules: [module.Navigation, module.Keyboard, module.Pagination],
            wrapperClass: wrapperClass,
            slideClass: slideClass,
            speed: 400,
            spaceBetween: 10,
            loopAdditionalSlides: 3,
            loop: true,
            keyboard: {
                enabled: true,
                pageUpDown: false,
            },
            navigation: {
                nextEl: buttonNext,
                prevEl: buttonPrev,
                disabledClass: `${navClass}--disabled`,
                hiddenClass: `${navClass}--hidden`,
                lockClass: `${navClass}--lock`,
            },
            breakpoints: {
                768: {
                    spaceBetween: 32,
                },
            },
            pagination: {
                el: pagination,
                type: 'custom',
                renderCustom: (swiper, current, total) => {
                    return `${current}/${total}`
                },
            },
        })
    }

    return {
        attach: attach,
    }
})()
behaviours.addBehaviour(blockImageSliderBehaviour)
