import Accordion from 'accordion-js'
import {Behaviour, behaviours} from '../../../global/scripts/behaviours/behaviour'

const blockAccordion: Behaviour = (function () {
    const attach = function (context: HTMLElement) {
        const baseClass = 'accordion'
        context.querySelectorAll<HTMLElement>(`.${baseClass}`).forEach((element) => {
            new Accordion(element, {
                ariaEnabled: false,
                showMultiple: false,
                elementClass: `${baseClass}__item`,
                triggerClass: `${baseClass}__itemTrigger`,
                panelClass: `${baseClass}__itemPanel`,
                activeClass: `${baseClass}__item--isActive`,
                openOnInit: [0],
            })
        })
    }

    return {
        attach: attach,
    }
})()

behaviours.addBehaviour(blockAccordion)
