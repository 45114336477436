import 'plyr/src/sass/plyr.scss'
import {behaviours} from '../../../global/scripts/behaviours/behaviour'

/* eslint-disable */
const heroBehaviour = (function () {
    const attach = function (context: HTMLElement) {
        context.querySelectorAll('.hero, .heroLegacy').forEach((element) => {
            initHeroVideo(element)
            initHeroImage(element)
        })
    }

    const throttle = (func: (...args: any[]) => void, limit: number) => {
        let inThrottle: boolean
        return function (this: any, ...args: any[]) {
            const context = this
            if (!inThrottle) {
                func.apply(context, args)
                inThrottle = true
                setTimeout(() => (inThrottle = false), limit)
            }
        }
    }

    const calculateAspectRatio = (block: HTMLElement, video: HTMLElement) => {
        const width = video.clientWidth
        const height = video.clientHeight
        const aspectRatio = width / height
        const aspectRatioLimit = 16 / 9
        const isTall = aspectRatio < aspectRatioLimit
        block.classList.toggle('hero--smallerThenWidescreen', isTall)
        block.classList.toggle('heroLegacy--smallerThenWidescreen', isTall)
    }

    const initHeroVideo = function (element: any) {
        const videoWrapper = element.querySelector('.hero__videoWrapper, .heroLegacy__videoWrapper')
        if (!videoWrapper) {
            return
        }

        calculateAspectRatio(element, videoWrapper)
        window.addEventListener(
            'resize',
            throttle(() => calculateAspectRatio(element, videoWrapper), 200)
        )
    }

    const initHeroImage = function (element: any) {
        const imageWrapper = element.querySelector('.hero__imageWrapper, .heroLegacy__imageWrapper')
        const image = element.querySelector('.hero__imageWrapper img, .heroLegacy__imageWrapper img')

        if (!imageWrapper || !image) {
            return
        }

        if (element.classList.contains('heroLegacy')) {
            image.addEventListener('load', () => {
                element.classList.add('heroLegacy--imageLoaded')
            })

            if (image.complete) {
                element.classList.add('heroLegacy--imageLoaded')
            }
        }

        image.addEventListener('load', () => {
            element.classList.add('hero--imageLoaded')
        })

        if (image.complete) {
            element.classList.add('hero--imageLoaded')
        }
    }

    return {
        attach: attach,
    }
})()
behaviours.addBehaviour(heroBehaviour)
