import {behaviours} from '../../../global/scripts/behaviours/behaviour'
import $ from 'jquery'

const mobileMenuBehaviour = (function () {
    const baseClass = 'mobileMenu'
    let resizeTimer = null
    let menu = null
    let menuContent = null

    const attach = function (context) {
        menu = context.querySelector(`.${baseClass}`)
        menuContent = context.querySelector(`.${baseClass}__content`)

        // No menu found, so exit
        if (!menu || !menuContent) {
            return false
        }

        // Behaviour already attached, so exit
        if (menu.classList.contains(`${baseClass}--behaviourAttached`)) {
            return false
        }

        menu.classList.add(`${baseClass}--behaviourAttached`)

        init(menu, menuContent)
    }

    const init = function (menu, menuContent) {
        // Goto submenu trigger
        menu.querySelectorAll(`.${baseClass}__submenuTrigger`).forEach((element) => {
            element.addEventListener('click', (event) => {
                event.preventDefault()

                const target = event.target.closest('li').querySelector(':scope > ul')
                menu.setAttribute('data-niveau', $(event.target).parents('ul').length)

                target.classList.add('show')

                menuContent.style.height = target.offsetHeight + 'px'
            })
        })

        // Go back trigger
        menu.querySelectorAll(`.${baseClass}__backlink`).forEach((element) => {
            element.addEventListener('click', (event) => {
                event.preventDefault()

                const activeNiveau = menu.getAttribute('data-niveau')
                const parent = element.parentElement

                menu.setAttribute('data-niveau', activeNiveau - 1)
                menuContent.outerHeight = 'auto'

                setTimeout(
                    function () {
                        parent.classList.remove('show')
                    }.bind(this),
                    300
                )
            })
        })

        window.addEventListener('resize', () => {
            clearTimeout(resizeTimer)
        })
    }

    const reset = function () {
        menu.querySelectorAll('ul').forEach((element) => {
            element.classList.remove('show')
        })
        menu.setAttribute('data-niveau', '0')
    }

    return {
        attach: attach,
        reset: reset,
    }
})()
behaviours.addBehaviour(mobileMenuBehaviour)
