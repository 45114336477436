import InView from 'inview'
import {behaviours} from '../../../global/scripts/behaviours/behaviour'

behaviours.addBehaviour(
    (() => {
        return {
            attach: function (context) {
                document.documentElement.classList.add('animation-ready')
                const animationParents = context.querySelectorAll('.animationGroup')
                animationParents.forEach((animationParent) => {
                    InView(animationParent, function (isInView, data) {
                        // 15% visible
                        const percentageVisible = data.elementOffsetTopInViewHeight < data.inViewHeight * 0.85
                        // 200 pixels visible
                        const fixedAmountVisible = data.elementOffsetTopInViewHeight < data.inViewHeight - 200
                        if (percentageVisible || fixedAmountVisible) {
                            animationParent.classList.add('animationGroup--started')
                            this.destroy()
                        }
                    })
                })
            },
        }
    })()
)
