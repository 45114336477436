import {behaviours} from '../../../global/scripts/behaviours/behaviour.ts'

const blockTeasersBehaviour = (function () {
    const attach = function (context) {
        const blocks = context.querySelectorAll('.blockTeasers--sliderEnabled')

        if (blocks.length === 0) {
            return
        }

        // Async load swiper library and init slideshow when loaded
        import(/* webpackChunkName: "slider" */ 'swiper').then((module) => {
            blocks.forEach((block) => init(block, context, module))
        })
    }

    const init = function (block, context, module) {
        const Swiper = module.default
        const baseClass = 'blockTeasers'
        const slider = block.querySelector(`.${baseClass}__slider`)
        const wrapperClass = `${baseClass}__items`
        const slideClass = `${baseClass}__item`

        const navClass = 'sliderNavIcon'
        const buttonNext = block && block.querySelector(`.${navClass}--next`)
        const buttonPrev = block && block.querySelector(`.${navClass}--prev`)

        new Swiper(slider, {
            modules: [module.Navigation],
            wrapperClass: wrapperClass,
            slideClass: slideClass,
            speed: 400,
            spaceBetween: 10,
            slidesPerView: 'auto',
            navigation: {
                nextEl: buttonNext,
                prevEl: buttonPrev,
                disabledClass: `${navClass}--disabled`,
                hiddenClass: `${navClass}--hidden`,
                lockClass: `${navClass}--lock`,
            },
            breakpoints: {
                768: {
                    spaceBetween: 20,
                },
                1400: {
                    slidesPerView: 3,
                    spaceBetween: 32,
                },
            },
        })
    }

    return {
        attach: attach,
    }
})()
behaviours.addBehaviour(blockTeasersBehaviour)
