import {Sticky} from 'sticky-ts'

import {behaviours} from '../../../global/scripts/behaviours/behaviour'

const blockHistoryBehaviour = (function () {
    const attach = function (context) {
        const blocks = context.querySelectorAll('.blockHistory')

        if (blocks.length === 0) {
            return
        }

        blocks.forEach((block) => init(block, context))
    }

    const init = function () {
        new Sticky('.blockHistory__dot')
    }

    return {
        attach: attach,
    }
})()
behaviours.addBehaviour(blockHistoryBehaviour)
