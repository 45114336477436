import {behaviours} from '../../../global/scripts/behaviours/behaviour'

import {disableBodyScroll, enableBodyScroll} from 'body-scroll-lock'

const mobileDrawerBehaviour = (function () {
    const baseClass = 'mobileDrawer'
    let drawer: HTMLElement | null = null

    const attach = function (context: HTMLElement) {
        drawer = context.querySelector(`.${baseClass}`)
        const toggleButtons = context.querySelectorAll<HTMLElement>('.mobileMenuTrigger')

        // No menu found, so exit
        if (!drawer) {
            return false
        }

        // Behaviour already attached, so exit
        if (drawer.classList.contains(`${baseClass}--behaviourAttached`)) {
            return false
        }

        drawer.classList.add(`${baseClass}--behaviourAttached`)

        toggleButtons.forEach((button) => {
            drawer && init(drawer, button)
        })

        window.addEventListener('keydown', (e) => {
            if (e.key === 'Escape') {
                close()
            }
        })
    }

    const open = function () {
        if (!drawer) {
            return false
        }

        const menu = drawer.querySelector('.mobileMenu')
        drawer.classList.add(`${baseClass}--open`)
        document.documentElement.classList.add('drawerIsOpen')
        if (menu) {
            disableBodyScroll(menu)
        }
    }

    const close = function () {
        if (!drawer) {
            return false
        }

        const menu = drawer.querySelector('.mobileMenu')
        drawer.classList.remove(`${baseClass}--open`)
        document.documentElement.classList.remove('drawerIsOpen')

        if (menu) {
            enableBodyScroll(menu)
        }
    }

    const init = function (drawer: HTMLElement, toggleButton: HTMLElement) {
        toggleButton.addEventListener('click', function () {
            const isOpen = drawer.classList.contains(`${baseClass}--open`)
            if (isOpen) {
                close()
            } else {
                open()
            }
        })
    }

    return {
        attach: attach,
        close: close,
        open: open,
    }
})()
behaviours.addBehaviour(mobileDrawerBehaviour)
