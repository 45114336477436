import {behaviours} from '../../../global/scripts/behaviours/behaviour'

const skipToContentBehaviour = (function () {
    const attach = function (context) {
        const links = context.querySelectorAll('.skipToContent')

        if (links.length === 0) {
            return
        }

        const heroHeight = document?.querySelector('.hero,.heroLegacy').clientHeight ?? 500

        links.forEach((link) =>
            link.addEventListener('click', (e) => {
                e.preventDefault()
                window.scrollTo({
                    top: heroHeight + link.clientHeight / 2,
                    behavior: 'smooth',
                })
            })
        )
    }

    return {
        attach: attach,
    }
})()
behaviours.addBehaviour(skipToContentBehaviour)
