import {behaviours} from '../../../global/scripts/behaviours/behaviour'

const blockServicesSliderBehaviour = (function () {
    const attach = function (context: HTMLElement) {
        const blocks = context.querySelectorAll<HTMLElement>('.blockHeroUsps')

        if (blocks.length === 0) {
            return
        }

        import(/* webpackChunkName: "slider" */ 'swiper').then((module) => {
            blocks.forEach((block) => init(block, module))
        })
    }

    const init = function (block: HTMLElement, module: typeof import('swiper')) {
        const Swiper = module.default

        const baseClass = 'blockHeroUsps'
        const sliderElement = block.querySelector<HTMLElement>(`.${baseClass}__slider`)
        const wrapperClass = `${baseClass}__items`
        const slideClass = `${baseClass}__item`
        const slidesPerViewDesktop = 3

        const slidesPerViewMobile = 1
        const slidesPerViewTablet = 2
        const spaceBetweenDesktop = 48
        const spaceBetweenTablet = 25

        const simulateTouch = true

        if (!sliderElement) {
            return false
        }

        new Swiper(sliderElement, {
            wrapperClass: wrapperClass,
            slideClass: slideClass,
            speed: 400,
            slidesPerView: slidesPerViewMobile,
            spaceBetween: 20,
            slideActiveClass: `${baseClass}__item--active`,
            slideVisibleClass: `${baseClass}__item--visible`,
            watchSlidesProgress: true,
            breakpoints: {
                768: {
                    spaceBetween: spaceBetweenTablet,
                    slidesPerView: slidesPerViewTablet,
                },
                1024: {
                    spaceBetween: spaceBetweenDesktop,
                    slidesPerView: slidesPerViewDesktop,
                    simulateTouch: simulateTouch,
                },
            },
        })
    }

    return {
        attach: attach,
    }
})()

behaviours.addBehaviour(blockServicesSliderBehaviour)
