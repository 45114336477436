import {behaviours} from '../../../global/scripts/behaviours/behaviour'

const backgroundSliderBehaviour = (function () {
    const attach = function (context) {
        const sliders = context.querySelectorAll('.backgroundSlider')

        if (sliders.length === 0) {
            return
        }

        // Async load swiper library and init slideshow when loaded
        import(/* webpackChunkName: "slider" */ 'swiper').then((module) => {
            sliders.forEach((slider) => init(slider, context, module))
        })
    }

    const init = function (slider, context, module) {
        const Swiper = module.default
        const baseClass = 'backgroundSlider'
        const wrapperClass = `${baseClass}__items`
        const slideClass = `${baseClass}__item`

        new Swiper(slider, {
            modules: [module.EffectFade, module.Autoplay],
            effect: 'fade',
            wrapperClass: wrapperClass,
            slideClass: slideClass,
            speed: 1000,
            disabled: true,
            autoplay: {
                delay: 5000,
            },
            loop: true,
            on: {
                afterInit: function () {
                    document.dispatchEvent(new CustomEvent('backgroundSlider:afterInit'))
                },
            },
        })
    }

    return {
        attach: attach,
    }
})()

behaviours.addBehaviour(backgroundSliderBehaviour)
