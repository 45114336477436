import {behaviours} from '../../../global/scripts/behaviours/behaviour'

const blockUspsBehaviour = (function () {
    const attach = function (context) {
        const blocks = context.querySelectorAll('.blockUsps')

        if (blocks.length === 0) {
            return
        }

        // Async load swiper library and init slideshow when loaded
        import(/* webpackChunkName: "slider" */ 'swiper').then((module) => {
            blocks.forEach((block) => init(block, context, module))
        })
    }

    const init = function (block, context, module) {
        const Swiper = module.default
        const baseClass = 'blockUsps'
        const slider = block.querySelector(`.${baseClass}__slider`)
        const wrapperClass = `${baseClass}__usps`
        const slideClass = `${baseClass}__usp`

        new Swiper(slider, {
            wrapperClass: wrapperClass,
            slideClass: slideClass,
            speed: 400,
            spaceBetween: 10,
            slidesPerView: 'auto',
            breakpoints: {
                1400: {
                    slidesPerView: 4,
                },
            },
        })
    }

    return {
        attach: attach,
    }
})()
behaviours.addBehaviour(blockUspsBehaviour)
