import {behaviours} from '../../../global/scripts/behaviours/behaviour.ts'

const blockProjectsBehaviour = (function () {
    const attach = function (context) {
        const blocks = context.querySelectorAll('.blockProjects')

        if (blocks.length === 0) {
            return
        }

        blocks.forEach((block) => init(block, context))
    }

    const init = function (block) {
        const blockSelector = '.blockProjects'
        const blockClass = 'blockProjects'
        const buttonFilterToggle = block.querySelector(`${blockSelector}__filterToggle`)
        if (buttonFilterToggle) {
            buttonFilterToggle.addEventListener('click', () => {
                block.classList.toggle(`${blockClass}--filtersOpen`)
            })
        }

        if (window.location.href.indexOf('_sft') > 0) {
            block.classList.add(`${blockClass}--filtersOpen`)
        }
    }

    jQuery(document).on('sf:ajaxfinish', () => {
        attach(document)
    })

    return {
        attach: attach,
    }
})()
behaviours.addBehaviour(blockProjectsBehaviour)
