import {behaviours} from '../../../global/scripts/behaviours/behaviour.ts'

const blockVideoBehaviour = (function () {
    const attach = function (context) {
        const blocks = context.querySelectorAll('.blockVideo')

        if (blocks.length === 0) {
            return
        }

        blocks.forEach((block) => init(block, context))
    }

    const init = function () {}

    return {
        attach: attach,
    }
})()
behaviours.addBehaviour(blockVideoBehaviour)
