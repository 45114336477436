import {behaviours} from '../../../global/scripts/behaviours/behaviour'

const SearchBarBehaviour = (function () {
    const attach = function (context: HTMLElement) {
        const blocks = context.querySelectorAll<HTMLElement>('.searchBar')

        if (blocks.length === 0) {
            return
        }

        blocks.forEach((block) => init(block, context))
    }

    const init = function (block: HTMLElement, context: HTMLElement) {
        window.addEventListener('keydown', (e) => {
            if (e.key === 'Escape') {
                const input = block.querySelector<HTMLInputElement>('.searchForm__input')
                block.classList.remove('searchBar--active')
                if (input) {
                    input.blur()
                    input.value = ''
                }
                context.querySelectorAll('.searchTrigger').forEach((trigger) => {
                    trigger.classList.remove('searchTrigger--active')
                })
                document.documentElement.classList.remove('searchOpen')
            }
        })

        context.querySelectorAll('.searchTrigger').forEach((trigger) => {
            if (!trigger.classList.contains('initialized')) {
                trigger.classList.add('initialized')
                trigger.addEventListener('click', () => {
                    // Focus on search field if current page is search page
                    const searchPage = document.querySelector('.blockSearchHeader')
                    if (searchPage) {
                        const searchPageInput = searchPage.querySelector<HTMLInputElement>('.searchForm__input')

                        if (!searchPageInput) {
                            return false
                        }

                        searchPageInput.focus()

                        // Close Menu

                        return true
                    }

                    // Open search bar in header
                    const isActive = block.classList.toggle('searchBar--active')
                    trigger.classList.toggle('searchTrigger--active', isActive)
                    document.documentElement.classList.toggle('searchOpen', isActive)
                    const input = block.querySelector<HTMLInputElement>('.searchForm__input')

                    if (!input) {
                        return false
                    }
                    if (isActive) {
                        input.value = ''
                        input.focus()
                    } else {
                        input.blur()
                        input.value = ''
                    }
                })
            }
        })
    }

    return {
        attach: attach,
    }
})()

behaviours.addBehaviour(SearchBarBehaviour)
