import {behaviours} from '../../../global/scripts/behaviours/behaviour'

import {SwiperModule} from 'swiper/types'

const blockServicesSliderBehaviour = (function () {
    const attach = function (context: HTMLElement) {
        const blocks = context.querySelectorAll<HTMLElement>('.blockServices--sliderEnabled')

        if (blocks.length === 0) {
            return
        }

        import(/* webpackChunkName: "slider" */ 'swiper').then((module) => {
            blocks.forEach((block) => init(block, module))
        })
    }

    const init = function (block: HTMLElement, module: typeof import('swiper')) {
        const Swiper = module.default
        const NavigationModule = module.Navigation as SwiperModule
        const EffectModule = module.EffectCreative as SwiperModule
        const PaginationModule = module.Pagination as SwiperModule

        const baseClass = 'blockServices'
        const sliderElement = block.querySelector<HTMLElement>(`.${baseClass}__slider`)
        const wrapperClass = `${baseClass}__items`
        const slideClass = `${baseClass}__item`
        const slidesPerViewDesktop = 2

        const slidesPerViewMobile = 1
        const spaceBetweenDesktop = 18
        const spaceBetweenTablet = 25

        const simulateTouch = true

        const navClass = 'sliderNavIcon'
        const buttonNext = block?.querySelector<HTMLElement>(`.${navClass}--next`)
        const buttonPrev = block?.querySelector<HTMLElement>(`.${navClass}--prev`)

        if (!sliderElement) {
            return false
        }

        new Swiper(sliderElement, {
            modules: [NavigationModule, EffectModule, PaginationModule],
            wrapperClass: wrapperClass,
            slideClass: slideClass,
            speed: 400,
            slidesPerView: slidesPerViewMobile,
            spaceBetween: 20,
            slideActiveClass: `${baseClass}__item--active`,
            slideVisibleClass: `${baseClass}__item--visible`,
            watchSlidesProgress: true,
            navigation: {
                nextEl: buttonNext,
                prevEl: buttonPrev,
                disabledClass: `${navClass}--disabled`,
                hiddenClass: `${navClass}--hidden`,
                lockClass: `${navClass}--lock`,
            },
            breakpoints: {
                768: {
                    spaceBetween: spaceBetweenTablet,
                },
                1024: {
                    spaceBetween: spaceBetweenDesktop,
                    slidesPerView: slidesPerViewDesktop,
                    simulateTouch: simulateTouch,
                },
            },
        })
    }

    return {
        attach: attach,
    }
})()

behaviours.addBehaviour(blockServicesSliderBehaviour)
